import React, { useEffect, useState } from 'react';
import { Button, PageHeader } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";

import { Link } from "react-router-dom";

import {
    PlusCircleTwoTone,
    HomeTwoTone,
    ExclamationCircleTwoTone,
    LogoutOutlined,
    LoginOutlined,
    SettingTwoTone
  } from '@ant-design/icons';

function AuthButton(props) {
    if (props.isAuthenticated) {
        return (
            <LogoutButton />
        )
    } else {
        return (
            <LoginButton />
        )
    }
}

function LoginButton() {
    const { loginWithRedirect } = useAuth0();
    return (<Button key="login" onClick={() => loginWithRedirect()} shape="round"><LoginOutlined /> Login</Button>)
}

function LogoutButton() {
    const { logout } = useAuth0();
    return (<Button key="logout" onClick={() => logout({ returnTo: "https://tonkatsuu.net/home" })} shape="round"><LogoutOutlined /> Logout</Button>)
}

function CreateButton(props) {
    if (props.isAuthenticated){
        return(<Link to="/create" key="create"><Button shape="round"><PlusCircleTwoTone /> Create</Button></Link>)
    }else{
        return (<></>)
    }
}

function IssueButton(props) {
    if (props.isAuthenticated){
        return(<Link to="/issues" key="2"><Button shape="round"><ExclamationCircleTwoTone /> Issues</Button></Link>)
    }else{
        return (<></>)
    }
}

function SettingsButton(props) {
    if (props.isAuthenticated){
        return(<Link to="/settings" key="settings"><Button shape="round"><SettingTwoTone /> Settings</Button></Link>)
    }else{
        return (<></>)
    }
}

function NavTitle() {
    const { isAuthenticated, user, isLoading } = useAuth0();
    const [authenticationButton, setAuthenticationButton] = useState(<AuthButton key={5} isAuthenticated={isAuthenticated} />);
    const [createButton, setCreateButton] = useState(<CreateButton key={6} isAuthenticated={isAuthenticated} />);
    const [issueButton, setIssueButton] = useState(<IssueButton key={7} isAuthenticated={isAuthenticated} />);
    const [settingsButton, setSettingsButton] = useState(<SettingsButton key={8} isAuthenticated={isAuthenticated} />);
    useEffect(() => {
        const doSomething = async () => {
            setAuthenticationButton(<AuthButton key={1} isAuthenticated={isAuthenticated} />);
            setCreateButton(<CreateButton key={2} isAuthenticated={isAuthenticated} />);
            setIssueButton(<IssueButton key={3} isAuthenticated={isAuthenticated}/>);
            setSettingsButton(<SettingsButton key={4}isAuthenticated={isAuthenticated}/>);
        };
        if (!isLoading) {
            doSomething();
        }

    }, [isLoading])
    return (
        <PageHeader
            className="site-page-header"
            title="Tonkatsuu Issue Tracker"
            subTitle="我是一個網站"
            extra={[
                <Link to="/home" key="0"><Button shape="round"><HomeTwoTone /> Home</Button></Link>,
                issueButton,
                createButton,
                settingsButton,
                authenticationButton
            ]}
        />
    )
}

export default NavTitle;