import { Button, Form, Checkbox, Input } from 'antd';
import axios from 'axios';

function Login() {
  const onFinish = values => {
    axios.post("https://tonkatsuu.net/api/login", values)
      .then(res => {
        console.log(res.data);
      })
      .catch(err => console.log(err));
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
          </Button>
      </Form.Item>
    </Form>
  )
}


export default Login;