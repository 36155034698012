import React, { useState, useEffect } from 'react';
import './App.css';

import { Link } from 'react-router-dom';
import { Button, List, Comment, PageHeader, Card, Row, Descriptions, Badge, Avatar, Skeleton, Form, Col, message, Tag, Alert } from 'antd';
import axios from 'axios';
import Editor from "rich-markdown-editor";

import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  BugOutlined,
  EditTwoTone,
  BookTwoTone,
  BugTwoTone,
  ExclamationCircleTwoTone
} from '@ant-design/icons';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

function CommentEditor({ onChange, onSubmit, submitting, value }) {
  return (
    <Form {...layout}>
      <Form.Item>
        <Editor
          readOnly={false}
          onChange={onChange}
          onSave={options => console.log("Save triggered", options)}
          defaultValue={value}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} onClick={onSubmit} shape="round" type="primary">
          Add Comment
        </Button>
      </Form.Item>
    </Form>
  )
}

function IssueStatus(props) {
  console.log(props);
  if (props.status === "active") {
    return (<Badge status="success" text={"Active"} />);
  } else {
    return (<Badge status="warning" text={"Archived"} />)
  }
}

function IssueType(props) {
  if (props.type === "issue") {
    return (
      <><ExclamationCircleTwoTone /> Issue</>
    )
  } else if (props.type === "bug") {
    return (
      <><BugTwoTone /> Bug</>
    )
  } else if (props.type === "report") {
    return (
      <><BookTwoTone /> Report</>
    )
  }
}

function Issue(props) {
  let { id } = useParams();

  let defaultText = "";
  const [commentInput, setCommentInput] = useState(defaultText);
  const [created, setCreated] = useState("2018-04-24 18:00:00")
  const [author, setAuthor] = useState("Arthur B. Gillingsley")
  const [issueType, setIssueType] = useState("bug");
  const [title, setTitle] = useState("Nimbus 2000 bucked user");
  const [issueID, setIssueID] = useState("941e38de-5c88-11eb-ae93-0242ac130002");
  const [owner, setOwner] = useState("Brian G. Kingsley");
  const [members, setMembers] = useState(["jenniferpwang", "eringwong"]);
  // const [server, setServer] = useState("Hogwarts");
  const [issueText, setIssueText] = useState("Nimbus 2000 broom experiencing curse, unsure how to proceed.");
  const [submitting, setSubmitting] = useState(false);
  const [isIssueLoading, setIsIssueLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [username, setUsername] = useState("ryanjchen2@gmail.com");
  const [issueStatus, setIssueStatus] = useState("active");
  const [issueStatusTag, setIssueStatusTag] = useState(<IssueStatus status={"active"} />);
  const [memberTags, setMemberTags] = useState(members.map(member => <Tag>{member}</Tag>))

  const [unAuthorized, setUnauthorized] = useState(false);

  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  const handleSubmit = () => {
    if (!commentInput || commentInput === defaultText) {
      return;
    }

    setSubmitting(true);

    const submitCommentWithToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        axios.post("https://tonkatsuu.net/api/comments/", {
          "post_id": id,
          "text": commentInput,
          "username": username,
          "server": "Boilers"
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(res => {
            let comment_data = res.data.data;
            setComments(comments.concat({ "comment_id": comment_data.id, "created": comment_data.created, "post_id": id, "text": `${commentInput}`, "username": username }))
            console.log(res);
            setSubmitting(false);
            setCommentInput("");
            message.success("Comment posted!");
          })
          .catch(err => console.log(err));
      } catch (e) {
        console.log(e.message);
      }
    };

    submitCommentWithToken();

  }

  useEffect(() => {
    const getIssueDataWithToken = async () => {
      const accessToken = await getAccessTokenSilently();
      axios.get("https://tonkatsuu.net/api/issue/" + id + "?server=Boilers", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then(res => {
          if(res.data.status_code != 200){
            setUnauthorized(true);
          }else{
            let issue = res.data.data;
  
            setCreated(issue.timestamp);
            setAuthor(issue.author);
            setIssueType(issue.issue_type);
            setTitle(issue.title);
            setIssueID(issue.id);
  
            setOwner(issue.owner);
            setIssueText(issue.text);
            setMembers(issue.members);
            setMemberTags(issue.members.map(member => <Tag>{member}</Tag>));
            setIssueStatus(issue.status.toLowerCase());
            setIssueStatusTag(<IssueStatus status={issue.status.toLowerCase()} />);
            const { email } = user;
            setUsername(email);
          }
          

          setIsIssueLoading(false);

        })
        .catch(err => console.log(err))
    }
    if (!isLoading) {
      getIssueDataWithToken();

      axios.get("https://tonkatsuu.net/api/comments?post_id=" + id + "&server=Boilers")
        .then(res => {
          let comments = res.data.data;
          console.log(comments);
          setComments(comments);
        })
    }

  }, [isLoading])

  return (
    <Card style={{ width: '100%' }}>
      {
        unAuthorized &&
        <Row justify="space-around">
          <Col xs={24} sm={24} lg={18}>
            <Alert
              message="Unauthorized"
              description="You can't see this!"
              type="error"
              closable
              showIcon
            />
          </Col>
        </Row>
      }
      {
        (isIssueLoading || unAuthorized) ? <Skeleton /> :
          <>
            <PageHeader
              className="site-page-header"
              title={title}
              subTitle={issueID}
              extra={[
                <Link to={{
                  pathname: "/edit"
                }} state={{
                    issueType: issueType,
                    title: title,
                    members: members,
                    issueText: issueText,
                    owner: owner,
                    postID: id,
                    issueStatus: issueStatus
                }}><Button shape="round"><EditTwoTone /> Edit</Button></Link>
              ]}
              avatar={{ icon: (<BugOutlined />) }}
            />
            <Descriptions bordered>
              <Descriptions.Item label="Created">{created}</Descriptions.Item>
              <Descriptions.Item label="Author">{author}</Descriptions.Item>
              <Descriptions.Item label="Owner">{owner}</Descriptions.Item>
              <Descriptions.Item label="Members">{memberTags}</Descriptions.Item>
              <Descriptions.Item label="Issue Type"><IssueType type={issueType} /></Descriptions.Item>
              <Descriptions.Item label="Status">{issueStatusTag}</Descriptions.Item>
            </Descriptions>
            <br />
            <Editor defaultValue={issueText} readOnly={true} />

            <List
              className="comment-list"
              header={`${comments.length} replies`}
              itemLayout="horizontal"
              dataSource={comments}
              renderItem={item => (
                <li>
                  <Comment
                    actions={item.actions}
                    author={item.username}
                    content={(<Editor defaultValue={item.text} readOnly={true} />)}
                    datetime={item.created}
                  />
                </li>
              )}
            />
            <Row justify="space-around">
              <Col xs={24} sm={24} lg={18}>
                {isLoading ? <Skeleton active />
                  : <Comment
                    author={user.name}
                    avatar={
                      <Avatar
                        src={user.picture}
                        alt="Han Solo"
                      />}

                    content={
                      <CommentEditor
                        onChange={setCommentInput}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        value={commentInput}
                      />
                    }
                  />}
              </Col>
            </Row>
          </>
      }
    </Card>
  )
}

export default Issue;