import React, { useState, useEffect } from 'react';
import './App.css';
import { DateTime, Settings } from 'luxon';
import { Typography, List, Row, Avatar, Col, Skeleton } from 'antd';
import axios from 'axios';

import {
    BugOutlined
} from '@ant-design/icons';

import { useAuth0 } from "@auth0/auth0-react";

const { Title } = Typography;

function getIssueData(issue) {
    return { title: issue.title, description: issue.text.substring(0, 50) + " ...", id: issue.id }
}

function Home() {
    const [welcome, setWelcome] = useState("加油！");
    const [yourIssues, setYourIssues] = useState([]);
    const [name, setName] = useState("faceless one");
    const [issuesLoading, setIssuesLoading] = useState(true);
    const [profilePicture, setProfilePicture] = useState("https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png")

    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    Settings.defaultZoneName = "Asia/Taipei";

    useEffect(() => {

        const doSomething = async () => {
            const accessToken = await getAccessTokenSilently();

            const { email, name, picture } = user;

            axios.get(`https://tonkatsuu.net/api/issues/${email}?server=Boilers`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then(res => {
                    if (res.data.status_code == 200) {
                        let assignedIssuesResponse = res.data.data;
                        let assignedIssues = assignedIssuesResponse.map(getIssueData);

                        setYourIssues(assignedIssues);
                    }
                    setIssuesLoading(false);
                })
                .catch(err => console.log(err));

            setName(name);
            setProfilePicture(picture);
        };

        if (!isLoading && isAuthenticated) {
            doSomething();
        }

    }, [isLoading]);

    return (
        !isAuthenticated ? 
            <>
            <Row justify="space-around">
                <Col><Title>Welcome! Please log in.</Title></Col>
            </Row>
            <Row justify="space-around">
                <Col><img src="https://i.imgur.com/EvU6D6E.png" /></Col>
            </Row>
            </> :
                <>
                {/* <Row> */}
                    <Col>
                        <Row>
                            <Title>{welcome}</Title>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Title level={5}>{DateTime.local().toLocaleString(DateTime.DATETIME_MED)}</Title>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={16}>
                            <Col span={12}>
                                <Title level={2}>Assigned to you</Title>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={22}>
                                {issuesLoading ?
                                    <List
                                        itemLayout="horizontal">
                                        <List.Item>
                                            <Skeleton avatar paragraph={{ rows: 1 }} />
                                        </List.Item>
                                        <List.Item>
                                            <Skeleton avatar paragraph={{ rows: 1 }} />
                                        </List.Item>
                                        <List.Item>
                                            <Skeleton avatar paragraph={{ rows: 1 }} />
                                        </List.Item>
                                    </List> : <List
                                        itemLayout="horizontal"
                                        dataSource={yourIssues}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<Avatar icon={<BugOutlined />} />}
                                                    title={<a href={"/issue/" + item.id} >{item.title}</a>}
                                                    description={item.description}
                                                />
                                            </List.Item>
                                        )}
                                    />}
                            </Col>
                        </Row>
                    </Col>

                    {/* <Col span={12}>
                        <Row justify="center">
                            <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 200 }} src={profilePicture} />

                        </Row>
                        <br />
                        <Row justify="center">
                            <Title level={5}>Welcome, {name}.</Title>
                        </Row>
                    </Col> */}
                {/* </Row> */}
                </>
    )
}

export default Home;