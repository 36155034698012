import React, { useState, useEffect } from 'react';
import './App.css';

import { Link, useLocation } from 'react-router-dom';
import {
    Button,
    Card,
    Row,
    Input,
    Form,
    Col,
    message,
    Typography,
    Select,
    Result,
    Badge
} from 'antd';
import axios from 'axios';
import Editor from "rich-markdown-editor";

import {
    BugTwoTone,
    ExclamationCircleTwoTone,
    UserOutlined,
    BookTwoTone,
    EditTwoTone
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { compareDocumentPosition } from 'domutils';

const { Title } = Typography;
const { Option } = Select;

function EditIssue(props) {
    const {user} = useAuth0();

    const location = useLocation();
    console.log(location)


    const [issueType, setIssueType] = useState(location.state.issueType);
    const [title, setTitle] = useState(location.state.title);
    const [members, setMembers] = useState(location.state.members);
    const [issueText, setIssueText] = useState(location.state.issueText);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [owner, setOwner] = useState(location.state.owner);
    const [users, setUsers] = useState([]);
    const [issueStatus, setIssueStatus] = useState(location.state.issueStatus)
    const [postID, setPostID] = useState(location.state.postID);

    const {getAccessTokenSilently} = useAuth0();
    
    const handleChange = (value) => {
        setTitle(value.target.value);
    }

    const handleSubmit = () => {
        const hide = message.loading('Editing new issue..', 0);
        setSubmitting(true);

        const submitPostWithToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                axios.post("https://tonkatsuu.net/api/edit", {
                    "username": "ryanjchen2@gmail.com",
                    "server": "Boilers",
                    "text": issueText,
                    "members": members,
                    "owner": owner,
                    "issue_type": issueType,
                    "title": title,
                    "id": postID,
                    "status": issueStatus,
                    "editor": user.name
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then(res => {
                    hide();
                    setSubmitted(true);
                })
                .catch(err => console.log(err));
            } catch (e) {
                console.log(e.message);
            }
        }

        submitPostWithToken();
        
    }

    useEffect(() => {
        const getUsers = async () => {
            try {
                const accessToken = await getAccessTokenSilently();

                axios.get("https://tonkatsuu.net/api/users/all",
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}` 
                    }
                })
                .then(res => {
                    let users = res.data.data;
                    let userOptions = users.map(user => {
                        return (<Option key={user.username}>{user.name}</Option>);
                    })
                    setUsers(userOptions);
                })
                .catch(err => console.log(err));
            } catch (e) { 
                console.log(e.message);
            }
        }

        getUsers();
    }, [])

    return (
        <Card style={{ width: '100%' }}>
            {
                submitted ? 
                <Result
                status="success"
                    title="Successfully edited an issue!"
                    extra={[<Link to={"/issue/" + postID}>
                    <Button type="primary" key="console">
                        Go to issue!
                    </Button>
                    </Link>
                    ]}
                /> :
                    <>
                        <Title level={2}><EditTwoTone /> Editing an issue ...</Title>
                        <Form layout="horizontal">
                            <Row justify="space-around">
                                <Col span={11}>
                                    <Form.Item label="Title">
                                        <Input onChange={handleChange} defaultValue={title} placeholder="Enter in a title here"/>
                                    </Form.Item>
                                    <Form.Item label="Submission Type">
                                        <Select defaultValue={issueType} placeholder="Select issue type ..." style={{ width: "100%" }} onChange={setIssueType}>
                                            <Option value="issue"><ExclamationCircleTwoTone /> Issue</Option>
                                            <Option value="bug"><BugTwoTone /> Bug</Option>
                                            <Option value="report"><BookTwoTone /> Report</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Status">
                                        <Select defaultValue={issueStatus} placeholder="Select status ..." style={{ width: "100%" }} onChange={setIssueStatus}>
                                            <Option value="active"><Badge status="success" text={"Active"} /></Option>
                                            <Option value="archived"><Badge status="warning" text={"Archived"} /></Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Owner">
                                        <Select
                                            value={owner} 
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select owner"
                                            onChange={setOwner}
                                        >
                                            {users}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Members">
                                        <Select
                                            value={members} 
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select members"
                                            onChange={setMembers}
                                        >
                                            {users}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Title level={4}>
                                Description
                            </Title>
                            <Row justify="center">
                                <Col span={23}>
                                    <Editor
                                        readOnly={true}
                                        onChange={setIssueText}
                                        onSave={options => console.log("Save triggered", options)}
                                        defaultValue={issueText}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row justify="left">
                                <Col span={23}>
                                    <Form.Item>
                                        <Button htmlType="submit" loading={submitting} onClick={handleSubmit} shape="round" type="primary">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>

                    </>
            }
        </Card>
    )
}

export default EditIssue;