import React from 'react';
import './App.css';

import {
    Row,
    Col
} from 'antd';

import EditIssue from "./EditPost";

function WrappedEditIssue(props) {
    return (
        <Row justify="center">
            <Col sm={24} md={24} lg={14}>
                <EditIssue {...props} />
            </Col>
        </Row>
    )
}

export default WrappedEditIssue;