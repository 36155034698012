import React, { useState, useEffect } from 'react';
import './App.css';
import { DateTime } from 'luxon';
import { Typography, List, Row, Avatar, Col, Card, Input, Button, Form, Alert } from 'antd';
import axios from 'axios';

import {
    BugOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';

import { useAuth0 } from "@auth0/auth0-react";

const { Title } = Typography;

function CreateUser() {
    const [email, setEmail] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [userCreationError, setUserCreationError] = useState(false);
    const [errorCode, setErrorCode] = useState(400);
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");

    const { getAccessTokenSilently } = useAuth0();

    const handleSubmit = () => {
        const createUser = async () => {
            const token = await getAccessTokenSilently();
            try {
                axios.post("https://tonkatsuu.net/api/users/create", {
                    "email": email.target.value,
                },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        setSubmitting(false);
                        console.log(res);
                        if (res.data.status_code == 401){
                            setUserCreationError(true);
                            setErrorCode(res.data.status_code);
                        } else {
                            let userInfo = res.data.data;
                            if (userInfo.statusCode == 200) {
                                setUserEmail(userInfo.email);
                                setUserPassword(userInfo.password);
                                setUserCreationError(false);
                                setUserCreated(true);
                            } else {
                                setUserCreationError(true);
                                setErrorCode(userInfo.statusCode);
                            }

                        }
                    })
                    .catch(err => console.log(err));
            } catch (e) {
                console.log(e);
            }
        }


        setSubmitting(true);

        if (email != "") {
            createUser();
        } else {
            setSubmitting(false);
        }


    }

    return (
        <Card title={"Create a new user"}>
            <Form>
                <Row justify="space-around">
                    <Col span={16}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email.',
                                },
                                {
                                    required: true,
                                    message: 'An email is required.',
                                },
                            ]}
                        >
                            <Input size="large" value={email} onChange={setEmail} placeholder="Enter user email here" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            <Button size="large" shape="round" type="primary" onClick={handleSubmit} icon={<PlusCircleOutlined />} loading={submitting}>Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {
                userCreated &&
                <Row justify="space-around">
                    <Col span={20}>
                        <Alert
                            message="Successfully created user"
                            description={"Created user with email " + userEmail + " and password " + userPassword + "."}
                            type="success"
                            closable
                            showIcon
                        />
                    </Col>

                </Row>
            }
            {
                userCreationError &&
                <Row justify="space-around">
                    <Col span={20}>
                        <Alert
                            message="User creation error"
                            description={"Creating user with email " + email.target.value + " resulted in error code " + errorCode + "."}
                            type="error"
                            closable
                            showIcon
                        />
                    </Col>
                </Row>
            }
        </Card>
    )
}

function EditUser() {
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [userCreationError, setUserCreationError] = useState(false);
    const [errorCode, setErrorCode] = useState(400);
    const [message, setMessage] = useState("");

    const { getAccessTokenSilently, user } = useAuth0();

    const handleSubmit = () => {
        const createUser = async () => {
            const token = await getAccessTokenSilently();
            try {
                const {email, user_id} = user;
                console.log(user.user_id);
                axios.post("https://tonkatsuu.net/api/users/update", {
                    "name": name.target.value,
                    "email": email,
                    "user_id": user.user_id
                    
                },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        setSubmitting(false);
                        console.log(res);
                        if (res.data.status_code == 401){
                            setUserCreationError(true);
                            setErrorCode(res.data.status_code);
                        } else {
                            let response = res.data;
                            if (response.status_code <= 300) {
                                setUserCreated(true);
                                setUserCreationError(false);
                                setMessage(response.message);
                            } else {
                                setUserCreationError(true);
                                setErrorCode(response.message);
                            }

                        }
                    })
                    .catch(err => console.log(err));
            } catch (e) {
                console.log(e);
            }
        }


        setSubmitting(true);

        if (name != "") {
            createUser();
        } else {
            setSubmitting(false);
        }


    }

    return (
        <Card title={"Edit user's name"}>
            <Form>
                <Row justify="space-around">
                    <Col span={16}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'A name is required.',
                                },
                            ]}
                        >
                            <Input size="large" value={name} onChange={setName} placeholder="Enter new name" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            <Button size="large" shape="round" type="primary" onClick={handleSubmit} icon={<PlusCircleOutlined />} loading={submitting}>Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {
                userCreated &&
                <Row justify="space-around">
                    <Col span={20}>
                        <Alert
                            message="Successfully edited user"
                            description={message}
                            type="success"
                            closable
                            showIcon
                        />
                    </Col>

                </Row>
            }
            {
                userCreationError &&
                <Row justify="space-around">
                    <Col span={20}>
                        <Alert
                            message="User edit error"
                            description={message}
                            type="error"
                            closable
                            showIcon
                        />
                    </Col>
                </Row>
            }
        </Card>
    )
}

function Settings() {
    return (
        <div>
            <Title>
                Settings
            </Title>
            <CreateUser />
            <EditUser/>
        </div>
    )
}

export default Settings;