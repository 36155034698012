import React, { useState, useEffect } from 'react';
import './App.css';

import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    Row,
    Input,
    Form,
    Col,
    message,
    Typography,
    Select,
    Result
} from 'antd';
import axios from 'axios';
import Editor from "rich-markdown-editor";

import {
    BugTwoTone,
    ExclamationCircleTwoTone,
    UserOutlined,
    BookTwoTone
} from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;
const children = [];
for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

function CreatePost(props) {
    const [issueType, setIssueType] = useState("issue");
    const [title, setTitle] = useState("");
    const [members, setMembers] = useState([]);
    const [issueText, setIssueText] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);;
    const [users, setUsers] = useState(children);
    const [postID, setPostID] = useState("");
    const [unauthorized, setUnauthorized] = useState(false);

    const { user, getAccessTokenSilently } = useAuth0();
    const { name, picture, email } = user;
    
    const owner = name;

    const handleChange = (value) => {
        setTitle(value.target.value);
    }

    const handleSubmit = () => {
        const hide = message.loading('Posting new issue..', 0);
        setSubmitting(true);

        const createPost = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                console.log(accessToken);

                axios.post("https://tonkatsuu.net/api/issue", {
                    "username": name,
                    "server": "Boilers",
                    "text": issueText,
                    "members": members,
                    "owner": email,
                    "issue_type": issueType,
                    "title": title
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}` 
                    }
                })
                .then(res => {
                    hide();
                    setSubmitted(true);

                    if(res.data.status_code == 401){
                        setUnauthorized(true);
                    }else{
                        setPostID(res.data.data);
                    }
                })
                .catch(err => console.log(err));
            } catch (e) {
                console.log(e.message);
            }
        };

        createPost();        
    }

    useEffect(() => {
        const getUsers = async () => {
            try {
                const accessToken = await getAccessTokenSilently();

                axios.get("https://tonkatsuu.net/api/users/all",
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}` 
                    }
                })
                .then(res => {
                    let users = res.data.data;
                    let userOptions = users.map(user => {
                        return (<Option key={user.username}>{user.name}</Option>);
                    })
                    setUsers(userOptions);
                })
                .catch(err => console.log(err));
            } catch (e) { 
                console.log(e.message);
            }
        }

        getUsers();
    }, [])

    return (
        <Card style={{ width: '100%' }}>
            { unauthorized &&
                <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to create new issues."
                />
            }
            {
                (submitted) ? 
                <Result
                status="success"
                    title="Successfully posted an issue!"
                    extra={[<Link to={"/issue/" + postID}>
                    <Button type="primary" key="console">
                        Go to issue!
                    </Button>
                    </Link>
                    ]}
                /> :
                    <>
                        <Title level={2}>New issue</Title>
                        <Form layout="horizontal">
                            <Row justify="space-around">
                                <Col span={11}>
                                    <Form.Item label="Title">
                                        <Input onChange={handleChange} placeholder="Enter in a title here"/>
                                    </Form.Item>
                                    <Form.Item label="Submission Type">
                                        <Select value={issueType} placeholder="Select issue type ..." style={{ width: "100%" }} onChange={setIssueType}>
                                            <Option value="issue"><ExclamationCircleTwoTone /> Issue</Option>
                                            <Option value="bug"><BugTwoTone /> Bug</Option>
                                            <Option value="report"><BookTwoTone /> Report</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Owner">
                                        <Input placeholder={owner} prefix={<UserOutlined />} />
                                    </Form.Item>
                                    <Form.Item label="Members">
                                        <Select
                                            value={members} 
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select members"
                                            onChange={setMembers}
                                        >
                                            {users}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Title level={4}>
                                Description
                            </Title>
                            <Row justify="center">
                                <Col span={23}>
                                    <Editor
                                        readOnly={false}
                                        onChange={setIssueText}
                                        onSave={options => console.log("Save triggered", options)}
                                        defaultValue={issueText}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row justify="left">
                                <Col span={23}>
                                    <Form.Item>
                                        <Button htmlType="submit" loading={submitting} onClick={handleSubmit} shape="round" type="primary">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>

                    </>
            }
        </Card>
    )
}

export default CreatePost;