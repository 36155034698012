import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Tag, Table, Row, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';

import {
  DatabaseFilled
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

const {Title} = Typography;

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    key: 'owner',
    align: "center"
  },
  {
    title: 'Issue type',
    key: 'issue_type',
    dataIndex: 'issue_type',
    align: 'center',
    render: tag => (
      <Tag color={'volcano'} key={tag}>
        {tag}
      </Tag>
    ),
  },
  {
    title: 'Last modified',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'Link',
    dataIndex: 'id',
    key: 'id',
    render: id => (
      <Link to={"/issue/" + id}>
        <Button type="primary" shape="round" block>
          Link
      </Button>
      </Link>
    ),
  }
];

function IssueTable() {
  const [isTableLoading, setTableLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const {getAccessTokenSilently, isLoading} = useAuth0();
  useEffect(() => {
    setTableLoading(true);
  }, [])

  useEffect(() => {

    const getUserMetadata = async () => {
  
      try {
        const accessToken = await getAccessTokenSilently();
        axios.get(
          "https://tonkatsuu.net/api/issues?server=Boilers",
          {
            headers: {
              Authorization: `Bearer ${accessToken}` 
          }
        })
          .then(res => {
            let issues = res.data.data;
            issues = issues.map(issue => {
              issue.key = issue.id
              return issue
            })
            setDataSource(issues);
            setTableLoading(false);
          })
          .catch(err => console.log(err))
      } catch (e) {
        console.log(e.message);
      }
    }

    if(!isLoading){
      getUserMetadata();
    }
    
  }, [isLoading])

  return (
    <>
      <Title>
        <DatabaseFilled /> Issues
      </Title>
      {
        isTableLoading ? <Row justify="center"><Spin size="large" /> </Row>:
          <>
            <Table bordered columns={columns} dataSource={dataSource} />
          </>
      }
    </>
  )
}

export default IssueTable;