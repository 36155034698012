import React, { useEffect, useState } from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { Button, PageHeader, Row, Col } from 'antd';

import Issue from "./Issue";
import Login from "./Login";
import Home from "./Home";
import IssueTable from "./IssueTable";
import CreatePost from './CreatePost';
import WrappedEditIssue from './WrappedEditIssue';
import NavTitle from './NavTitle';
import Settings from "./Settings"

import {
  PlusCircleTwoTone,
  LoginOutlined
} from '@ant-design/icons';

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Auth0ProviderWithHistory from "./auth-with-history";

import { Typography } from 'antd';

const { Title } = Typography;

function AuthButton(props) {
  if (props.isAuthenticated) {
    return (
      <LogoutButton />
    )
  } else {
    return (
      <LoginButton />
    )
  }

}

function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  return (<Button onClick={() => loginWithRedirect()} shape="round">Login</Button>)
}

function LogoutButton() {
  const { loginWithRedirect } = useAuth0();
  return (<Button onClick={() => loginWithRedirect()} shape="round">Logout</Button>)
}

function IssueTableRoute() {
  return (
    <Row justify="center">
      <Col sm={22} md={22} lg={14}>
        <IssueTable />
      </Col>
    </Row>
  )
}

function IssueRoute() {
  return (
    <Row justify="center">
      <Col sm={24} md={24} lg={14}>
        <Issue />
      </Col>
    </Row>
  )
}

function LoginRoute() {
  return (
    <Row justify="center">
      <Col span={6}>
        <Login />
      </Col>
    </Row>
  )
}

function CreateRoute() {
  return (
    <Row justify="center">
      <Col sm={24} md={24} lg={14}>
        <CreatePost />
      </Col>
    </Row>
  )
}

function HomeRoute() {
  return (
    <Row justify="center">
      <Col span={16}>
        <Home />
      </Col>
    </Row>
  )
}

function SettingsRoute() {
  return (
    <Row justify="center">
      <Col span={16}>
        <Settings />
      </Col>
    </Row>
  )
}

function BoginButton() {
  const { loginWithRedirect } = useAuth0();
  return (<Button type="primary" size="large" key="login" onClick={() => loginWithRedirect()} block><LoginOutlined /> Login and get started</Button>)
}

function Landing() {
  return (
    <Row justify="center">
      <Row gutter={16}>
        <Col span={12}>
          <Row>
            <Title>tonkatsuu</Title>
          </Row>
          <br />
          <Row><Title level={4}>A <span style={{ "color": "#46bfc2" }}>simple</span> and flexible issue and bug tracker.</Title></Row>
          <Row><Title level={4}>Quick <span style={{ "color": "#46bfc2" }}>reporting</span> with ability to go in-depth</Title></Row>
          <Row><Title level={4}><span style={{ "color": "#46bfc2" }}>Non-destructive</span> issue tracking that stays with you.</Title></Row>
          <Row><Title level={4}>Easily deployable with <span style={{ "color": "#46bfc2" }}>Docker</span>.</Title></Row>
          <br />
          <Row><BoginButton /></Row>
        </Col>
        <Col flex="auto"><Row justify="center"><img src="https://i.imgur.com/EvU6D6E.png" /></Row></Col>
      </Row>
    </Row>
  )
}

function App() {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const [authenticationButton, setAuthenticationButton] = useState(<AuthButton isAuthenticated={false} />)

  useEffect(() => {
    const doSomething = async () => {
      setAuthenticationButton(<AuthButton isAuthenticated={isAuthenticated} />)
    };
    if (!isLoading) {
      doSomething();
    }

  }, [isLoading])
  const domain = "tonkatsuu.us.auth0.com"
  const clientId = "5kcaOMEFnocPSYsE7E5UbAvnjUn7ebeb"
  const redirectUri = "https://tonkatsuu.net/home"


  return (

    <Router>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={redirectUri}
        audience={"https://tonkatsuu.com/authenticate"}
        scope={"read:comments"}>
        <div className="App">
          <NavTitle />
          <Routes>
            <Route path="/issues" element={<IssueTableRoute />} />
            <Route path="/" element={<Landing />} />
            <Route path="/issue/:id" element={<IssueRoute />} />
            <Route path="/login" element={<LoginRoute />} />
            <Route path="/create" element={<CreateRoute />} />
            <Route path="/edit" element={<WrappedEditIssue />} />
            <Route path="/home" element={<HomeRoute/>} />
            <Route path="/settings" element={<SettingsRoute/>} />
          </Routes>
        </div>
      </Auth0Provider>
    </Router>
  );
}

export default App;
